/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
// import packageJson from "../../../package.json";

function AdminFooter() {
  return (
    <>
      <Container fluid>
        <footer className="footer pt-0">
          <Row className="align-items-center justify-content-lg-between">
            <Col lg="6">
              <div className="copyright text-center text-lg-left text-muted d-none d-lg-block">
                © <a href="https://hokkybangunan.co.id" target="_blank" rel="noreferrer"><b>HOKKY BANGUNAN</b></a> {new Date().getFullYear()}
              </div>
            </Col>
            {/* <Col>
              <div className="text-right">v {packageJson.version}</div>
            </Col> */}
          </Row>
        </footer>
      </Container>
    </>
  );
}

export default AdminFooter;
