
import Login from "views/pages/login/Index.js";
import KaryawanIzin from "views/pages/Staff/Izin/Index";
import CreateKaryawanIzin from "views/pages/Staff/Izin/Create";
import AbsensiStaff from "views/pages/Staff/Absensi/Index";
import IndexCheckin from "views/pages/Staff/Absensi/Checkin";
import IndexCheckout from "views/pages/Staff/Absensi/Checkout";
import IndexDinasPage from "views/pages/Staff/Dinas/Index";
import CreateDinasCuk from "views/pages/Staff/Dinas/Create";
import DetailHrdIzinDinasKaryawan from "views/pages/Staff/Dinas/Detail";
import DetailHrdIzinKaryawanncok from "views/pages/Staff/Izin/Detail";
import ProfileMobile from "views/pages/Staff/Profile/Index";
import EditPasswordProfileMobile from "views/pages/Staff/Profile/EditPassword";
import LogAksesMobileProfile from "views/pages/Staff/Profile/LogAkses";
import CheckinSuksesPage from "views/pages/Staff/Absensi/CheckinSukses";
import CheckoutSuksesPage from "views/pages/Staff/Absensi/CheckoutSukses";
import createizinsukses from "views/pages/Staff/Izin/CreateSukses";
import createdinassukses from "views/pages/Staff/Dinas/CreateSukses";
import IndexKehadiranCok from "views/pages/Staff/Kehadiran/Index";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/auth",
    hidden: true,
  },
  {
    collapse: true,
    name: "Karyawan",
    icon: "fa fa-users text-green",
    state: "KaryawanCollapse",
    hidden : true,
    roles: [
      "ROLE_SUPERADMIN",
      "ROLE_ADMIN",
      "ROLE_KARYAWAN",
      "ROLE_USER",
      "ROLE_OWNER",
    ],
    views: [
      {
        path: "/staff-absen/sukses-pulang",
        name: "Absensi Pulang Sukses",
        miniName: "",
        component: CheckoutSuksesPage,
        layout: "/mobile",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/staff-absen/pulang",
        name: "Absensi Pulang",
        miniName: "",
        component: IndexCheckout,
        layout: "/mobile",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/staff-absen/sukses",
        name: "Absensi Masuk Sukses",
        miniName: "",
        component: CheckinSuksesPage,
        layout: "/mobile",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/staff-absen/masuk",
        name: "Absensi Masuk",
        miniName: "",
        component: IndexCheckin,
        layout: "/mobile",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/staff-absen",
        name: "Absensi",
        miniName: "",
        component: AbsensiStaff,
        layout: "/mobile",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/dispensasi-karyawan/izin/sukses-izin",
        name: "Absensi Masuk Sukses",
        miniName: "",
        component: createizinsukses,
        layout: "/mobile",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/dispensasi-karyawan/izin/detail/:id",
        name: "Detail Izin",
        component: DetailHrdIzinKaryawanncok,
        layout: "/mobile",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/dispensasi-karyawan/izin/create",
        name: "Karyawan Izin Create",
        component: CreateKaryawanIzin,
        layout: "/mobile",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/dispensasi-karyawan/izin",
        name: "Izin Page",
        miniName: "",
        component: KaryawanIzin,
        layout: "/mobile",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/dinas/detail/:id",
        name: "Detail Dinas",
        component: DetailHrdIzinDinasKaryawan,
        layout: "/mobile",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/dinas/sukses-dinas",
        name: "Create Dinas",
        component: createdinassukses,
        layout: "/mobile",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/dinas/create",
        name: "Create Dinas",
        component: CreateDinasCuk,
        layout: "/mobile",
        hidden: true,
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/dinas/page",
        name: "Dinas Page",
        miniName: "",
        component: IndexDinasPage,
        layout: "/mobile",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/kehadiran/page",
        name: "Kehadiran Page",
        miniName: "",
        component: IndexKehadiranCok,
        layout: "/mobile",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/profile/new-password",
        name: "New Password",
        miniName: "",
        component: EditPasswordProfileMobile,
        layout: "/mobile",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/log-akses",
        name: "Log Akses",
        miniName: "",
        component: LogAksesMobileProfile,
        layout: "/mobile",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      },
      {
        path: "/profile",
        name: "profile",
        miniName: "",
        component: ProfileMobile,
        layout: "/mobile",
        roles: [
          "ROLE_SUPERADMIN",
          "ROLE_ADMIN",
          "ROLE_KARYAWAN",
          "ROLE_USER",
          "ROLE_OWNER",
        ],
      }
    ],
  },
  
];

export default routes;
