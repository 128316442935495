/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { Card, CardBody, Row, CardHeader, Col, Button, Container } from "reactstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import { getAddressMap } from "utils/getMapAddress";
import MobileHeader1 from "components/Headers/MobileHeader1";
import Halaman404 from "views/404";

export default function IndexCheckOut(props) {
  const token = localStorage.token;
  const warehouseId = localStorage.warehouse;
  const username = localStorage.username;
  let history = useHistory();

  const [imageFile, setImageFile] = useState(null);
  const [alert, setAlert] = useState(null);
  const [long, setLong] = useState(0);
  const [lat, setLat] = useState(0);
  const [address, setAddress] = useState("");
  const [isSpinner, setIsSpinner] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [cameraError, setCameraError] = useState(null);

  const videoRef = useRef(null); // Ref untuk elemen video
  const canvasRef = useRef(null); // Ref untuk elemen canvas

  useEffect(() => {
    const interval = setInterval(() => {
      updateLocation();
    }, 1500);
    return () => clearInterval(interval);
  }, []);

  const updateLocation = async () => {
    navigator.geolocation.getCurrentPosition(async (position) => {
      setLat(position.coords.latitude);
      setLong(position.coords.longitude);
  
      // Ambil data alamat dari API
      const pointAddress = await getAddressMap(position.coords.latitude, position.coords.longitude);
  
      // Periksa apakah data valid, lalu set display_name ke state address
      if (pointAddress?.display_name) {
        setAddress(pointAddress.display_name); // Ambil display_name langsung
      } else {
        setAddress("Alamat tidak ditemukan");
      }
    });
  };
  
    // const updateLocation = async () => {
    //   navigator.geolocation.getCurrentPosition(async (position) => {
    //     setLat(position.coords.latitude);
    //     setLong(position.coords.longitude);
    //     const pointAddress = await getAddressMap(position.coords.latitude, position.coords.longitude);
    //     if (pointAddress?.boundingbox?.[0]) {
    //       // setAddress(pointAddress.results[2].formatted_address);
    //       setAddress(pointAddress.boundingbox[0].display_name);
    //     }
    //   });
    // };

  // useEffect(() => {
  //   const startCamera = async () => {
  //     try {
  //       const stream = await navigator.mediaDevices.getUserMedia({
  //         video: { width: 480, height: 640, facingMode: "user" },
  //       });

  //       if (videoRef.current) {
  //         videoRef.current.srcObject = stream;
  //       }
  //     } catch (error) {
  //       setCameraError("Tidak dapat mengakses kamera. Pastikan Anda memberikan izin.");
  //     }
  //   };

  //   startCamera();

  //   return () => {
  //     if (videoRef.current?.srcObject) {
  //       const tracks = videoRef.current.srcObject.getTracks();
  //       tracks.forEach((track) => track.stop());
  //     }
  //   };
  // }, []);

  useEffect(() => {
    const startCamera = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode: "user" },
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (error) {
        setCameraError("Tidak dapat mengakses kamera. Pastikan Anda memberikan izin.");
      }
    };
  
    startCamera();
  
    // Cleanup function
    return () => {
      if (videoRef.current?.srcObject) {
        const tracks = videoRef.current.srcObject.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, []);

  useEffect(() => {
    if (imageFile && !isSubmitting) {
      setIsSubmitting(true);
      handleSubmit();
    }
  }, [imageFile]);

  // const captureImage = () => {
  //   if (videoRef.current && canvasRef.current) {
  //     const canvas = canvasRef.current;
  //     const context = canvas.getContext("2d");
  //     context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
  //     canvas.toBlob((blob) => {
  //       const file = new File([blob], "absensi.jpeg", { type: "image/jpeg" });
  //       setImageFile(file);
  //     }, "image/jpeg");
  //   }
  // };

  const captureImage = () => {
    if (videoRef.current && canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      // Menyesuaikan dimensi canvas dengan rasio video
      const videoWidth = videoRef.current.videoWidth;
      const videoHeight = videoRef.current.videoHeight;
      canvas.width = videoWidth;
      canvas.height = videoHeight;

      context.drawImage(videoRef.current, 0, 0, videoWidth, videoHeight);

      canvas.toBlob((blob) => {
        const file = new File([blob], "absensi.jpeg", { type: "image/jpeg" });
        setImageFile(file);
      }, "image/jpeg");
    }
  };

  const handleSubmit = () => {
    if (!imageFile) {
      setGagalAlert("Posisikan wajah anda di depan kamera selama 2 detik.");
      setIsSubmitting(false);
      return;
    }
    setIsSpinner(true);
    EditData();
  };

  const EditData = () => {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    };
    const myjson = JSON.stringify({
      warehouse_id: parseInt(warehouseId),
      username: username,
      latitude: lat,
      longitude: long,
      address: address,
    });

    let data = new FormData();
    data.append("body", myjson);
    data.append("pulang", imageFile);

    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/absensi-karyawan/check-out`, data, { headers })
      .then(() => {
        setIsSpinner(false);
        history.push("/mobile/staff-absen/sukses-pulang");
      })
      .catch((error) => {
        setGagalAlert(error.response?.data?.message || "Terjadi kesalahan saat mengirim data.");
      });
  };

  const setGagalAlert = (message) => {
    setIsSpinner(false);
    setAlert(
      <SweetAlert
        danger
        showConfirm
        confirmBtnText="Ok"
        title={message}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const ButtonBack = () => {
    // Hentikan kamera
    if (videoRef.current?.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
    }
    // Navigasi kembali
    history.push("/mobile/staff-absen");
  };

  return (
    <>
      {alert}
      <div className="d-none d-lg-block">
        <Halaman404 />
      </div>
      <div className="d-lg-none d-xl-none">
        {isSpinner ? (
          <Container className="mt--10 pb-10 login-container">
            <Row className="justify-content-center">
              <Col lg="5" md="7">
                <div className="d-flex flex-column align-items-center d-lg-none d-xl-none">
                  <img
                    src={require("assets/img/theme/loadingmobile1.gif").default}
                    style={{
                      textAlign: "center",
                      height: "12rem",
                      width: "12rem",
                      paddingTop: "1rem",
                    }}
                    alt="loading"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        ) : (
          <div>
            <MobileHeader1 name="" parentName="Master" />
            <Container className="mt--6" fluid>
              <Row md="12">
                <Col md="12">
                  <Card className="bg-secondary shadow">
                    <CardHeader className="bg-white border-0">
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div style={{ textAlign: "left" }}>
                          <Button color="link" onClick={ButtonBack}>
                            <i className="fa fa-arrow-circle-left fa-2x" />
                          </Button>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          <b>Absensi Pulang</b>
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <center>
                        <h6>{address}</h6>
                      </center>
                      <br />
                      {cameraError ? (
                        <p style={{ color: "red", textAlign: "center" }}>{cameraError}</p>
                      ) : (
                        <div>
                          <video
                            ref={videoRef}
                            autoPlay
                            playsInline
                            style={{
                              width: "100%",
                              maxWidth: "640px",
                              height: "auto",
                              borderRadius: "10px",
                              border: "1px solid #ddd",
                              transform: "scaleX(-1)", // Membuat mirror
                            }}
                          />
                          {/* <canvas
                            ref={canvasRef}
                            style={{ display: "none" }}
                            width={480}
                            height={640}
                          /> */}
                          <canvas
                            ref={canvasRef}
                            style={{ display: "none" }}
                          />
                           {address && address !== "Alamat tidak ditemukan" ? (
                            <Button color="primary" block onClick={captureImage}>
                              Ambil Foto
                            </Button>
                          ) : (
                            <p style={{ color: "gray", textAlign: "center" }}>
                              Menunggu lokasi...
                            </p>
                          )}
                          {/* <Button color="primary" block onClick={captureImage}>
                            Ambil Foto
                          </Button> */}
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </div>
    </>
  );
}
