/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	Label,
	FormGroup,
	Row,
	Input,
	CardHeader,
	Col,
	Button,
	Container,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import moment from "moment" ;
import SweetAlert from "react-bootstrap-sweetalert";
import SimpleHeaderMobile from "components/Headers/SimpleHeaderMobile"

export default function CreateDinasCuk() {
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
    let history = useHistory();
	const warehouseId = localStorage.warehouse;
	const username = localStorage.username;
	const [description, setDescription] = useState("");
	const [tanggal, setTanggal] = useState("");
	const [isSpinner, setIsSpinner] = useState(false);

	const handleSubmit = () => {
		setTimeout(() => {CreateDinas()}, 2000);
		setIsSpinner(true);
};

	const CreateDinas = () => {
		const headers = {
			Authorization: `Bearer ${token}`,
		};
        	let data = {
        		username: username,
                warehouse_id : parseInt(warehouseId),
                dinas_date : moment(tanggal).format("YYYY-MM-DD 00:00"),
                keterangan : description
        	};
		axios
			.post(`${process.env.REACT_APP_API_BASE_URL}/absensi-dinas/save`, data, { headers })
			.then(function (response) {
				// setSuccessAlert(response.data.message);
				setIsSpinner(false);
				history.push("/mobile/dinas/sukses-dinas")
				// setTimeout(() => (history.push("/mobile/dinas/page")), 1000);
			  })
			  .catch(function (error) {
					setGagalAlert(error.response.data.message);
					setIsSpinner(false);

			  });
		}
	  
		// const setSuccessAlert = (id) => {
		//   setAlert(
		// 	<SweetAlert
		// 	  success
		// 	  showConfirm
		// 	  confirmBtnText="Ok"
		// 	  title={id}
		// 	  onCancel={hideAlert}
		// 	  onConfirm={hideAlert}
		// 	/>
		//   )
		// }
	  
	  const setGagalAlert = (id) => {
	  setAlert(
		<SweetAlert
		danger
		showConfirm
		confirmBtnText="Ok"
		title={id}
		onCancel={hideAlert}
		onConfirm={hideAlert}
		/>
	  );
	  };
	  
	  const setQuestionAlert = () => {
	  setAlert(
		<SweetAlert
		warning
		showCancel
		confirmBtnText="Lanjutkan"
		confirmBtnBsStyle="danger"
		title="Apakah Kamu Yakin?"
		onConfirm={CreateDinas}
		onCancel={hideAlert}
		focusCancelBtn
		/>
	  );
	  };
	  
		const hideAlert = () => {
		  setAlert(null);
		};

	return (
		<>
		{alert}
		{isSpinner ? (
				<Container className="mt--10 pb-10 login-container">
				<Row className="justify-content-center">
						<Col lg="5" md="7">
								<div className="d-flex flex-column align-items-center d-lg-none d-xl-none">
									<img src={require("assets/img/theme/loadingmobile1.gif").default } style={{ textAlign:"center", height: "12rem", width: "12rem", paddingTop: "1rem" }} />
								</div>
						</Col>
					</Row>
				</Container>
			) : (
			<div>
				{/* <SimpleHeader name="Buat Dinas" parentName="Master" /> */}
				<SimpleHeaderMobile to="/mobile/dinas/page" />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
							{/* <CardBody> */}
								<Row md="12">
									<Col md="12">
										<Card className="bg-secondary shadow">
											<CardHeader className="bg-white border-0">
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
													<h2>Buat Dinas</h2>
                                                <div style={{ textAlign: 'left' }} className="d-lg-block d-none">
                                                    <Link className="btn btn-link" to="/mobile/dinas/page">
                                                        <i className="fa fa-arrow-circle-left fa-2x" /> 
                                                    </Link>
                                                    </div>
                                                </div>
											</CardHeader>
											<CardBody>
                                                <FormGroup row>
                                                    <Label className="form-control-label">Tanggal</Label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        autoComplete="off"
                                                        id="dateRequired"
                                                        type="date"
                                                        placeholder="Masukan Tanggal"
                                                        value={tanggal}
                                                        onChange={(e) => {
                                                            setTanggal(e.target.value);
                                                        }}
                                                        />
                                                </FormGroup>  
												<FormGroup row>
													<Label className="form-control-label">Keterangan Dinas</Label>
                                                        <Input
                                                            autoComplete="off"
                                                            className="form-control-alternative"
                                                            rows="13"
                                                            type="textarea"
                                                            name="deskripsi Item"
                                                            placeholder="Masukan Keterangan Dinas"
                                                            value={description}
                                                            onChange={(e) => {
                                                                setDescription(e.target.value);
                                                            }}
                                                        />
												</FormGroup>
											</CardBody>
											<Button color="danger" onClick={()=>handleSubmit()}>
													Simpan
											</Button>
										</Card>
									</Col>
								</Row>
						</div>
					</Row>
				</Container>
			</div>
			)}
		</>
	);
}
