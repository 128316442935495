/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import { CardBody, Media, Container, Row} from "reactstrap";
// import axios from 'axios';
import React, { useState, useEffect } from 'react';

function MobileHeader({ name, jabatan, picture }) {
  const getGreeting = () => {
    const now = new Date();
    const hours = now.getHours();

    if (hours < 11) {
      return 'Selamat Pagi';
    } else if (hours < 15) {
      return 'Selamat Siang';
    } else if (hours < 18) {
      return 'Selamat Sore';
    } else {
      return 'Selamat Malam';
    }
  };

  const [greeting, setGreeting] = useState(getGreeting());

  useEffect(() => {
    const interval = setInterval(() => {
      setGreeting(getGreeting());
    }, 60000); // Update every minute

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);


  return (
    <>
      <div className="header header-dark bg-gradient-info pb-6 content__title content__title--calendar" style={{ 
      borderBottomLeftRadius: '20px', 
      borderBottomRightRadius: '20px' 
    }}>
        <Container fluid>
          <div className="header-body">
              <CardBody>
                <Row>
                  <div className="col">
                  <Media className="align-items-center">
                  <a
                    className="avatar rounded-circle mr-2"
                    href="#pablo"
                    onClick={e => e.preventDefault()}
                  >
                    
                    {picture === "" ?(
                        <img
                          alt=""
                          src={"https://i.imgur.com/Hzxpp28.png"}
                        />
                    ):(
                      <img
                        alt=""
                        src={picture}
                      />
                    )}
                   
                  </a>
                    <Media>
                    <div style={{ textAlign: "left", color:"white"}}>
                      <span className="mb-2" style={{ fontSize: "12px"}}>
                        <b>{greeting}</b><br></br>
                      </span>
                      <span className="mb-2" style={{ fontSize: "15px"}}>
                        <b>{name}</b><br></br>
                      </span>
                    </div>
                    </Media>
                  </Media>
                  </div>
                  {/* <div className="marquee-container">
                    <div className="marquee-content">
                      Selamat datang di website kami! Selamat datang di website kami!
                    </div>
                  </div> */}
                </Row>
              </CardBody>
          </div>
        </Container>
      </div>
    </>
  );
}

MobileHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default MobileHeader;