/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { CardTitle, Badge, Card, Row, Col, CardBody, CardHeader, Container,Form } from 'reactstrap';
import { Link } from "react-router-dom";
import axios from 'axios';
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SimpleHeaderMobile from "components/Headers/SimpleHeaderMobile"
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import SweetAlert from "react-bootstrap-sweetalert";
import PerfectScrollbar from "react-perfect-scrollbar";
import  "../Style.css"

const IndexDinasPage = () => {
  const warehouse = localStorage.warehouse;
  const token = localStorage.token;
  const username = localStorage.username;
  const redirectPrefix = `/mobile/dinas/detail/`;
  const [alert, setAlert] = React.useState(null);
  const [rowIndex, setRowIndex] = useState(0);
  const [allAsset, setAllAsset] = useState([]);
  const [nameAsset, setNameAsset] = useState("");
  const [codeAsset, setCodeAsset] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerpage] = useState(10);
  const [totalItem, setTotalItem] = useState(0);
  const [currentSort, setCurrentSort] = useState("");
  const allInfo = JSON.parse(localStorage.allinfo);
  const Validasi = String(allInfo.privileges.filter((i) => i.privilege_name === "Sub Dinas").map((p) => p.read_access));
  

  let paginationOption = {
    page: page,
    alwaysShowAllBtns: true,
    override: true,
    showTotal: true,
    withFirstAndLast: false,
    sizePerPage: perPage,
    totalSize: totalItem,
    onPageChange: (page) => {
      updateDataTable(page, perPage, currentSort,  nameAsset, codeAsset, startDate, endDate);
    },
    sizePerPageRenderer: () => (
      <div className="dataTables_length" id="datatable-basic_length">
        <label>
          Show{" "}
          {
            <select
              name="datatable-basic_length"
              aria-controls="datatable-basic"
              className="form-control form-control-sm"
              onChange={(e) => {
                updateDataTable(page, e.target.value, currentSort)
              }}
            >
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          }{" "}
          entries.
        </label>
      </div>
    ),
  }

  const updateDataTable = (page, perPage, sort, nameAsset, codeAsset, startDate, endDate) => {
    getAsset(page, perPage, sort, nameAsset, codeAsset, startDate, endDate);
    setPage(page);
    setPerpage(perPage);
    setRowIndex((page - 1) * perPage);
    setCurrentSort(sort);
    setNameAsset(nameAsset);
    setCodeAsset(codeAsset);
    setStartDate(startDate);
    setEndDate(endDate);
  }

  const handleTableChange = (type, { sortField, sortOrder }) => {
    if (type === "sort") {
      let sort = `${sortField} ${sortOrder}`
      updateDataTable(page, perPage, sort,  nameAsset, codeAsset, startDate, endDate)
    }
  }

  useEffect(() => {
    getAsset(page, perPage, currentSort, "", "", "", "", "");
  }, []);

  const getAsset = (page) => {
    let filter = { 
      page: page, 
      per_page: 500,
      warehouse_id : parseInt(warehouse),
      username : username
    }
    const data = filter;
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/absensi-dinas/page`, data, { headers
    })
    .then(data => {
        setAllAsset(data.data.response);
        setPage(data.data.current_page + 1);
        setPerpage(data.data.per_page);
        setTotalItem(data.data.total_item);
    })
      .catch(function (error) {
        setAllAsset(error.response.data.response)
      })
  }

  const delateAsset = (id) => {
    axios.post(`${process.env.REACT_APP_API_BASE_URL}/asset/delete/${id}`, null, {

      headers: {
        Authorization: `Bearer ${token}`,
      }
    })
      .then(function (response) {
        setSuccessAlert();
        getAsset();
      })
      .catch(function (error) {
        setSuccessAlert(error.response.data.message)
      })
  }

  const setSuccessAlert = (id) => {
    setAlert(
      <SweetAlert
        danger
        showConfirm
        confirmBtnText="Ok"
        title={id}
        onCancel={hideAlert}
        onConfirm={hideAlert}
      />
    )
  }

  const hideAlert = () => {
    setAlert(null);
  }
  
  const ButtonKehadiran = async () => {window.location.href = "/admin/hrd/dinas"};


  return (
    <div style={{overflow:"hidden"}}>
      {alert}
      <SimpleHeaderMobile to="/mobile/staff-absen" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h2>Riwayat Dinas</h2>
                  <div style={{ textAlign: 'left' }} className="d-lg-block d-none">
                      <Link className="btn btn-link" to="/mobile/staff-absen">
                        <i className="fa fa-arrow-circle-left fa-2x" /> 
                      </Link>
                    </div>
                  <div style={{ textAlign: "right" }} >
                    <Link className="btn btn-danger d-lg-block d-none" to="/mobile/dinas/create">
                    <i className="fa fa-plus" aria-hidden="true"/> Tambah
                    </Link>
                    {/* {Validasi && Validasi === "1" && (
                    <Link className="btn btn-danger d-lg-none d-xl-none" onClick={ButtonKehadiran}>
                    <i className="fa fa-check-circle"  aria-hidden="true"/> Validasi
                      </Link>
                    )} */}
                  </div>
                  
                </div>
              </CardHeader>
              <CardBody>
              {allAsset === null ? (
                <div className="d-lg-block d-none">
                     <CardHeader>
                          <center><h3>DATA NOT FOUND</h3></center>
                      </CardHeader>
                      </div>
                    ):(
                <div className="d-lg-block d-none">
                <ToolkitProvider
                  rowNumber={rowIndex}
                  data={allAsset}
                  keyField="id"
                  columns={[
                    {
                      dataField: "no",
                      text: "#",
                      sort: true,
                      page: 1,
                      formatter: (cell, row, index) => {
                        let currentRow = ++index;
                        return currentRow + rowIndex;
                      }
                    },
                    {
                        dataField: "dinas_date",
                        text: "Tanggal Dinas",
                        sort: true,
                    },
                    {
                        dataField: "name",
                        text: "Nama",
                        sort: true,
                    },
                    {
                        dataField: "keterangan",
                        text: "Keterangan",
                        sort: true,
                    },
                    {
                        dataField: "approve",
                        text: "Status",
                        sort: true,
                        formatter: (cell, row) => {
                          return row.approve === 3
                            ? 
                              <Badge color="" className="badge-dot mr-4">
                                <i className="bg-danger" />
                                Proses
                              </Badge>
                            : row.approve === 4
                            ? <Badge color="" className="badge-dot mr-4">
                                <i className="bg-warning" />
                                Tidak Setuju
                              </Badge>
                            : <Badge color="" className="badge-dot mr-4">
                                <i className="bg-success" />
                                Setuju
                              </Badge>;
                        },
                    },
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      <BootstrapTable
                        remote
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        hover={true}
                        pagination={paginationFactory({ ...paginationOption })}
                        onTableChange={handleTableChange}
                      />
                    </div>
                  )}
                </ToolkitProvider>
                </div>
                    )}
                {allAsset === null ? (
                   <div className="d-lg-none d-xl-none">                     
                      <CardHeader>
                          <center><h3>DATA NOT FOUND</h3></center>
                      </CardHeader>
                      </div>

                    ):(
                      <PerfectScrollbar >
                        <div className="d-lg-none d-xl-none" style={{ top: 100, zIndex: "3", maxHeight: "35rem", paddingTop: "1rem" }}>
                          <Form >
                                {allAsset.map((v, i) => (
                                <Link
                                    to={redirectPrefix + v.id}
                                    id={"tooltip_" + v.id}
                                >
                                    <Card className="bg-gradient-danger" style={{ marginBottom: "0.5rem"}}>
                                    <CardBody>
                                        <Row>
                                        <div className="col">
                                            <CardTitle className="h5 text-uppercase text-muted mb-0 text-white">
                                            {v.dinas_date}
                                            </CardTitle>
                                            <span  style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    display: "-webkit-box",
                                                    lineClamp: 1,
                                                    WebkitLineClamp: 1,
                                                    WebkitBoxOrient: "vertical",
                                                }} 
                                                className="h5 font-weight-bold mb-0 text-white ">
                                            {v.keterangan}
                                            </span>
                                        </div>
                                        <Col className="col-auto">
                                            {
                                            v.approve === 3
                                                ? <div className="icon icon-shape bg-white text-yellow rounded-circle shadow"> 
                                                <i className="fa fa-question" /> 
                                                </div>
                                                : v.approve === 4
                                                ? <div className="icon icon-shape bg-white text-red rounded-circle shadow">  
                                                <i className="fa fa-times" /> 
                                                </div>
                                                : <div className="icon icon-shape bg-white text-green rounded-circle shadow">  
                                                <i className="fa fa-check" /> 
                                                </div>
                                            }
                                            
                                        </Col>
                                        </Row>
                                        {/* <p className="mt-3 mb-0 text-sm">
                                        <span className="text-white mr-2">
                                            <i className="fa fa-arrow-up" />
                                        </span>
                                        <span className="text-nowrap text-light">
                                        </span>
                                        </p> */}
                                    </CardBody>
                                    </Card>
                                </Link>
                                ))
                            } 
                          </Form>
                        </div>
                      </PerfectScrollbar>
                    )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
      <div className="d-lg-none d-xl-none">
       <Link 
        style={{
              paddingTop: 20,
              height: 70,
              width: 70, 
              borderRadius: "50%",
            }}  
            className="btn-danger text-center fixed-whatsapp encepnurdin" 
            to="/mobile/dinas/create"
            >
                <i className="fa fa-plus fa-2x" aria-hidden="true"/>
        </Link> 
        </div>
      {/* <a class='fixed-whatsapp encepnurdin' href='/mobile/dinas/create' rel='nofollow noopener' title='PlusCok' /> */}
    </div>
  );
}

export default IndexDinasPage;