/*eslint-disable*/
import React, { useState } from "react";
import {
	Card,
	CardBody,
	Label,
	FormGroup,
	Row,
	Input,
	CardHeader,
	CardFooter,
	Col,
	Button,
	Container,
    Form,
    FormFeedback 
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import SweetAlert from "react-bootstrap-sweetalert";
import MobileHeader1 from "components/Headers/MobileHeader1";

export default function EditPasswordProfileMobile() {
const [alert, setAlert] = React.useState(null);
const token = localStorage.token;
    let history = useHistory();
	const warehouseId = localStorage.warehouse;
	const username = localStorage.username;
	const [description, setDescription] = useState("");
	const [tanggal, setTanggal] = useState("");
    const [password,setPassword] = useState("")
    const [confirmpassword,setConfirmPassword] = useState("")
    const [passworderror, setPasswordError] = useState(null);

  const validateForm = () => {
    let error = false;
    if (password === confirmpassword) {
        setQuestionAlert();
    } 
    else {
        setPasswordError("invalid");
        error = true;
      }
    return error;
};

const handleformvalidasi = (e) => {
  e.preventDefault();
  if (!validateForm()) {
      setQuestionAlert();
  }
}


	const UpdatePasswordProfile = () => {
		const headers = {
			Authorization: `Bearer ${token}`,
		};
        	let data = {
        		username: username,
                password : confirmpassword,
        	};
		axios
			.post(`${process.env.REACT_APP_API_BASE_URL}/users/reset-password`, data, { headers })
			.then(function (response) {
				setSuccessAlert(response.data.message);
				setTimeout(() => (history.push("/mobile/profile")), 1000);
			  })
			  .catch(function (error) {
					setGagalAlert(error.response.data.message);
			  });
		}
	  
		const setSuccessAlert = (id) => {
		  setAlert(
			<SweetAlert
			  success
			  showConfirm
			  confirmBtnText="Ok"
			  title={id}
			  onCancel={hideAlert}
			  onConfirm={hideAlert}
			/>
		  )
		}
	  
	  const setGagalAlert = (id) => {
	  setAlert(
		<SweetAlert
		danger
		showConfirm
		confirmBtnText="Ok"
		title={id}
		onCancel={hideAlert}
		onConfirm={hideAlert}
		/>
	  );
	  };
	  
	  const setQuestionAlert = () => {
	  setAlert(
		<SweetAlert
		warning
		showCancel
		confirmBtnText="Lanjutkan"
		confirmBtnBsStyle="danger"
		title="Apakah Kamu Yakin?"
		onConfirm={UpdatePasswordProfile}
		onCancel={hideAlert}
		focusCancelBtn
		/>
	  );
	  };
	  
		const hideAlert = () => {
		  setAlert(null);
		};

	return (
		<>
		{alert}
			<div>
				<MobileHeader1 name="New Password" parentName="Master" />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
								<Row md="12">
									<Col md="12">
										<Card className="bg-secondary shadow">
											<CardHeader className="bg-white border-0">
											<div style={{ display: "flex", justifyContent: "space-between" }}>
                                                <div style={{ textAlign: 'left' }}>
                                                    <Link className="btn btn-link" to="/mobile/profile">
                                                        <i className="fa fa-arrow-circle-left fa-2x" /> 
                                                    </Link>
                                                    </div>
                                                </div>
											</CardHeader>
                                            <Form onSubmit={handleformvalidasi}>
											<CardBody>
                                                <FormGroup row>
                                                    <Label className="form-control-label">Password</Label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        autoComplete="off"
                                                        type="password"
                                                        placeholder="Password"
                                                        value={password}
                                                        onChange={(e) => {
                                                            setPassword(e.target.value);
                                                        }}
                                                        />
                                                </FormGroup>  
												<FormGroup row>
                                                    <Label className="form-control-label">Konfirmasi Password</Label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        autoComplete="off"
                                                        invalid={passworderror === "invalid"}
                                                        type="password"
                                                        placeholder="Konfirmasi Password"
                                                        value={confirmpassword}
                                                        onChange={(e) => {
                                                            setConfirmPassword(e.target.value);
                                                            if (e.target.value !== password) {
                                                                setPasswordError("");
                                                            }
                                                        }}
                                                        />
                                                        <FormFeedback>Password Tidak Sama</FormFeedback>
                                                </FormGroup>  
											</CardBody>
											{/* <center>
                                            <Button color="danger" type="submit">
													Simpan
											</Button>
											</center> */}
											<CardFooter>
												<center>
												<Button color="danger" type="submit">
													Simpan
												</Button>
												</center>
											</CardFooter>
                                            </Form>
										</Card>
									</Col>
								</Row>
						</div>
					</Row>
				</Container>
			</div>
		</>
	);
}
