/*eslint-disable*/
import React, { useEffect } from 'react';
import { 
    Card, 
    Row, 
    Col, 
    CardBody,
    Container, 
} from 'reactstrap';
import { Link } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation";

const CheckoutSuksesPage = () => {

  const successCallback = (position) => {
    // console.log(position);
  };
  
  const errorCallback = (error) => {
    // console.log(error);
  };
  
  navigator.geolocation.getCurrentPosition(successCallback, errorCallback);

  useEffect(() => {
    setTimeout(() => (window.location.href="/mobile/staff-absen"),3000);
  }, [])


  return (
    <div>
      <Container className="mt--8 pb-5 login-container">
						<Row className="justify-content-center">
							<Col lg="5" md="7">
								<Card className="bg-secondary border-0 mb-0 d-lg-none d-xl-none">
									<img alt="login" style={{ width: "100%" }} src={require("assets/img/theme/logosukses1.png").default} />
									<CardBody className="px-lg-5 py-lg-5">
                                    <h2><center>Absen Pulang Sukses</center></h2>
                                    <br></br>
                                    <center>Selamat beristirahat. Jangan lupa besok masuk tepat waktu ya .</center>
                                    <AvForm>
											<div className="text-center">
                          {/* <Button className="my-4" disabled={!captchaResponse} color="info" type="submit"> */}
                          {/* <Button className="my-4" color="info" onClick={}>
                            Kembali
                          </Button> */}
                        <Link className="my-4 btn btn-primary" to="/mobile/staff-absen">
                            Kembali
                        </Link>
											</div>
										</AvForm>
									</CardBody>
								</Card>
							</Col>
						</Row>
					</Container>
    </div>
  );
}

export default CheckoutSuksesPage;