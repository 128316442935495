/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import { Breadcrumb, BreadcrumbItem, Card, CardBody,CardFooter, Media, Container, Row, Col } from "reactstrap";
import axios from 'axios';
import React, { useEffect, useState , useRef} from 'react';

function MobileHeader1({ name, jabatan, picture }) {
//     const [name,setName] = useState("");
//   const [picture,setPicture] = useState("");
//   const [jabatan,setJabatan] = useState("");

//   useEffect(() => {
//     ceklangtut()
//     // getJamdanButton()
//     getByUser()
//   }, [])

//   const getByUser = () => {
//     const headers = {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`,
//     };
//     axios
//       .get(
//         `${process.env.REACT_APP_API_BASE_URL}/users/profil/${userid}`,
//         { headers }
//       )
//       .then((data) => {
//         setName(data.data.response.name);
//         setPicture(data.data.response.karyawan_image);
//         setJabatan(data.data.response.jabatan);
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   };

  return (
    <>
      <div className="header header-dark bg-gradient-info pb-6 content__title content__title--calendar">
        <Container fluid>
          <div className="header-body">
              <CardBody>
                <Row>
                  <div className="col">
                 
                  </div>
                </Row>
              </CardBody>
          </div>
        </Container>
      </div>
    </>
  );
}

MobileHeader1.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default MobileHeader1;