/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
	Card,
	CardBody,
	Label,
	FormGroup,
	Row,
	Input,
	CardHeader,
	Col,
	Container,
} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import moment from "moment" ;
import SimpleHeaderMobile from "components/Headers/SimpleHeaderMobile"

export default function DetailHrdIzinKaryawanncok(props) {
const token = localStorage.token;
	const [description, setDescription] = useState("");
	const [tanggal, setTanggal] = useState("");
    const [approve, setApprove] = useState("");
    const [name, setName] = useState("")

    useEffect(() => {
        getById();
      }, []);
    
      const getById = () => {
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
        axios
          .get(
            `${process.env.REACT_APP_API_BASE_URL}/absensi-form/get/${props.match.params.id}`,
            { headers }
          )
          .then((data) => {
            setDescription(data.data.response.keterangan);
            setName(data.data.response.username);
            setApprove(data.data.response.approve);
            setTanggal(moment(data.data.response.ijin_date, "YYYY-MM-DD").format("YYYY-MM-DD"));
           
          })
          .catch(function (error) {
            console.log(error);
          });
      };

	return (
		<>
			<div>
				{/* <SimpleHeader name="Detail Izin " parentName="Master" /> */}
        <SimpleHeaderMobile to="/mobile/dispensasi-karyawan/izin" />
				<Container className="mt--6" fluid>
					<Row>
						<div className="col">
								<Row md="12">
									<Col md="12">
										<Card className="bg-secondary shadow">
											<CardHeader className="bg-white border-0">
                                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                              <h2>Detail Izin</h2>
                                                <div style={{ textAlign: 'left' }} className="d-lg-block d-none">
                                                    <Link className="btn btn-link" to="/mobile/dispensasi-karyawan/izin">
                                                        <i className="fa fa-arrow-circle-left fa-2x" /> 
                                                    </Link>
                                                    </div>
                                                </div>
											</CardHeader>
											<CardBody>
                                                <FormGroup row>
                                                    <Label>Tanggal</Label>
                                                    <Input
                                                    disabled
                                                        className="form-control-alternative"
                                                        autoComplete="off"
                                                        id="dateRequired"
                                                        type="date"
                                                        placeholder="Masukan Tanggal"
                                                        value={tanggal}
                                                        onChange={(e) => {
                                                            setTanggal(e.target.value);
                                                        }}
                                                        />
                                                </FormGroup>  
												<FormGroup row>
													<Label>Nama</Label>
                                                        <Input
                                                        disabled
                                                            autoComplete="off"
                                                            className="form-control-alternative"
                                                            type="text"
                                                            name="deskripsi Item"
                                                            placeholder="Nama"
                                                            value={name}
                                                            onChange={(e) => {
                                                                setName(e.target.value);
                                                            }}
                                                        />
												</FormGroup>
												<FormGroup row>
													<Label>Keterangan Izin</Label>
                                                        <Input
                                                        disabled
                                                            autoComplete="off"
                                                            className="form-control-alternative"
                                                            rows="13"
                                                            type="textarea"
                                                            name="deskripsi Item"
                                                            placeholder="Masukan Keterangan Izin"
                                                            value={description}
                                                            onChange={(e) => {
                                                                setDescription(e.target.value);
                                                            }}
                                                        />
												</FormGroup>
											</CardBody>
										</Card>
									</Col>
								</Row>
						</div>
					</Row>
				</Container>
			</div>
		</>
	);
}
