/* eslint-disable */
import React, { useEffect, useState } from "react";
import {
  CardTitle,
  Badge,
  Card,
  Row,
  Col,
  CardBody,
  Button,
  CardHeader,
  Label,
  Input,
  Container,
  FormGroup,
  Collapse,
} from "reactstrap";
import axios from "axios";
import SimpleHeaderMobile from "components/Headers/SimpleHeaderMobile";
import "../Style.css";

const IndexKehadiranCok = () => {
  const warehouse = localStorage.warehouse;
  const token = localStorage.token;
  const username = localStorage.username;

  const [allAsset, setAllAsset] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [tanggal, setTanggal] = useState("");
  const [collapse, setCollapse] = useState(false);

  const toggle = () => setCollapse(!collapse);

  // Fetch Data
  const getAsset = (page, search_date = null) => {
    const filter = {
      page,
      per_page: 50,
      search_date: search_date || tanggal,
      warehouse_id: parseInt(warehouse),
      username: username,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/absensi-karyawan/page`,
        filter,
        { headers }
      )
      .then((res) => {
        const responseData = res.data.response || [];
        setAllAsset(responseData);
        setPage(res.data.current_page + 1);
        setPerPage(res.data.per_page);
      })
      .catch((error) => {
        console.error(error);
        setAllAsset([]);
      });
  };

  useEffect(() => {
    getAsset(page, tanggal);
  }, []);

  // Reset Filter
  const reset = () => {
    setTanggal("");
    getAsset(1, "");
  };

  return (
    <div style={{ overflow: "hidden" }}>
      <SimpleHeaderMobile to="/mobile/staff-absen" judul="Riwayat Kehadiran" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h3>Riwayat Kehadiran</h3>
                  <Button
                    color="link"
                    onClick={toggle}
                    style={{ marginBottom: "1rem" }}
                  >
                    {collapse ? (
                      <i className="fa fa-minus" aria-hidden="true"></i>
                    ) : (
                      <i className="fa fa-plus" aria-hidden="true"></i>
                    )}
                  </Button>
                </div>
                <Collapse isOpen={collapse}>
                  <div style={{ textAlign: "left" }}>
                    <Row className="row-cols-lg-auto g-3 align-items-center">
                      <Col>
                        <FormGroup>
                          <Label className="form-control-label">
                            Pilih Tanggal
                          </Label>
                          <Input
                            autoComplete="off"
                            type="date"
                            value={tanggal}
                            onChange={(e) => setTanggal(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <Label>&nbsp;</Label>
                        <Button
                          type="button"
                          onClick={() => getAsset(page, tanggal)}
                          className="btn btn-info"
                        >
                          <i className="fa fa-filter"></i>
                        </Button>
                      </Col>
                      <Col>
                        <Label>&nbsp;</Label>
                        <Button
                          type="button"
                          onClick={reset}
                          className="btn btn-secondary"
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Collapse>
              </CardHeader>

              {allAsset.length === 0 ? (
                <CardBody>
                  <center>
                    <h3>DATA NOT FOUND</h3>
                  </center>
                </CardBody>
              ) : (
                <div
                  style={{
                    height: "73vh",
                    overflowY: "auto",
                    cursor: "pointer",
                  }}
                >
                  {allAsset.map((v, index) => (
                    <Card style={{ marginBottom: "0rem" }} key={index}>
                      <CardBody>
                        <Row>
                          <div className="col">
                            <CardTitle className="h5 text-uppercase text-muted mb-0 text-black">
                              {v.tgl}&nbsp;{v.check}&nbsp;
                              {v.status_kerja === 1 ? (
                                <Badge color="success">{v.keterangan}</Badge>
                              ) : v.status_kerja === 2 ? (
                                <Badge color="danger">{v.keterangan}</Badge>
                              ) : (
                                <Badge color="warning">{v.keterangan}</Badge>
                              )}
                            </CardTitle>
                            <span className="h5 font-weight-bold mb-0 text-black">
                              {v.name}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <a
                              className="avatar rounded-circle mr-3"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              {v.karyawan_image ? (
                                <img alt="" src={v.karyawan_image} />
                              ) : (
                                <img
                                  alt=""
                                  src={"https://i.imgur.com/Hzxpp28.png"}
                                />
                              )}
                            </a>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  ))}
                </div>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default IndexKehadiranCok;
